.OurClients {
  text-align: center;
  background-color: #1a3e90;
  padding-top: 10px;
  padding-bottom: 30px;
}
.HeaderTitle {
  color: #fff !important;
  text-align: center;
  padding-bottom: 10px;
}
.Paper {
  color: #fff !important;
  background-color: #1a3e90 !important;
  max-width: 600px;
  /* width: 50vw;  */
  min-height: 140px;
  min-width: 100px;
}

.Spacing {
  padding: spacing(2);
}

.ConstructionMedia {
  max-width: 100%;
  object-fit: contain;
}

.CenterAlignGridItems {
  align-items: center !important;
}

.ConstructionType {
  max-width: 600px;
  width: 50vw; 
}
