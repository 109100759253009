.OurSolutions {
  text-align: center;
  background-color: #ffffff;
  padding-top: 10px;
}
.OurSolutionsHeaderTitle {
  color: #000 !important;
  text-align: center !important;
  padding-bottom: 10px;
}
.Leftcard {
  color:#fff !important;
  padding: 16px;
  text-align: center;
  background-color: #1a3e90 !important;
  
}
.Centercard {
  color:#fff !important;
  padding: 16px;
  text-align: center;
  background-color: #AA3C43 !important;

}
.Rightcard {
  color:#fff !important;
  padding: 16px;
  text-align: center;
  background-color:  #7a7a7c !important;

}

.Media {
    max-width:initial;
    height: 400px;
    object-fit:scale-down;
}

.SerivceButtons { 
    color:#fff !important; 
    border: 1px solid #fff !important;
}
