.OurPortfolio {
  text-align: center;
  background-color: #ffffff;
  padding-top: 10px;
}
.PortfolioHeaderTitle {
  color: rgb(0, 0, 0) !important;
  text-align: center !important;
  padding-bottom: 30px;
}
.PortfolioPaper {
  color: rgb(0, 0, 0) !important;
  background-color: #ffffff !important;
  max-width: 600px;
  min-height: 140px;
  min-width: 100px;
}

.Spacing {
  padding: spacing(2);
}

.CenterAlignGridItems {
  align-items: center !important;
}

.LogicMedia {
  max-width: 100%;
  object-fit: contain;
}