.NavComp {
  background-color: #fff !important;
  color: crimson !important;
}

.MobileNav {
  color: crimson !important;
}

a.MobileNavItem:link {
  color: crimson !important;
}

a.MobileNavItem:visited {
  color: crimson !important;
}

a.MobileNavItem {
    text-decoration: none;
}

.Grow {
  flex-grow: 1;
}
