.OurPartnersRoot {
  text-align: center;
  padding-top: 10px;
  background-color: #AA3C43;
  flex-grow: 1;
  padding-bottom: 10px;
  /* height: 500px; */
}

.PartnersGrid {
  justify-content: center;
}

.paper {
  background-color: #7a7a7c !important;
  padding: 2;
  text-align: center;
  height: 200px;
}

.MuiGrid-spacing-xs-3 {
  width: auto !important;
  margin: 0 !important;
}

.PartnerCard { 
  /* max-height: 100px; */
}

.CardMedia {
  height: 100px;
  /* max-height: 100px; */
  /* max-width: 100%; */
  width: inherit;
  max-width: 200px; 
  object-fit: contain;
}
