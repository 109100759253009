.FormStyle {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  min-height: 681px;
}

.FormCard {
  margin-top: 10px;
  box-shadow: unset !important;
}

.FormLoading {
  /* padding-top: 10%; */
  margin-top: 5rem;
}

.FormItemStyle {
  text-align: left;
}

.FormSubTitleStyle {
  font: bold;
  color: #aa3c43;
}

.FormLabelStyle {
  margin-bottom: 10px !important;
}

.TextFieldStyle {
  margin-bottom: 10px !important;
  width: 100% !important;
}

.SubmitButtonContainer {
  text-align: center !important;
}

.SubmitButton {
  color: #aa3c43 !important;
  border: 1px solid #aa3c43 !important;
  margin: 10px !important;
}
