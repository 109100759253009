.Social-media-icon {
    height: 35px;
    width: 35px;
    margin: 5px;
  }
  
  .Social-media-icon:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .SocialContainer {
    /* padding-bottom: 120px !important; */
    /* display: flex; */
    box-shadow: unset !important;
    justify-content: end;
    text-align: end;
    margin: 6px;
    padding-right: 3px;
    border: 0;
  }

  .SocialGrid {
    display: flex;
  }

  .Img {
      max-height: 100px;
  }

  