.GallaryRoot {
    text-align: center;
    padding-top: 10px;
    background-color: #1a3e90;
    flex-grow: 1;
    padding-bottom: 10px;
    /* height: 500px; */
  }
  
  .PartnersGrid {
    justify-content: center;
  }
  
  .paper {
    background-color: #7a7a7c !important;
    padding: 2;
    text-align: center;
    height: inherit;
  }
  
  .MuiGrid-spacing-xs-3 {
    width: auto !important;
    margin: 0 !important;
  }
  
  .PartnerCard { 
    /* max-height: 100px; */
  }
  
  .GallaryMedia {
    height: 400px;
    /* max-height: 100px; */
    /* max-width: 100%; */
    width: inherit;
    max-width: 400px; 
    object-fit: contain;
  }

  .GallaryMediaDialog {
    height: 75vh;
    /* max-height: 100px; */
    /* max-width: 100%; */
    width: inherit;
    max-width: 75vw; 
    object-fit: contain;
  }
  