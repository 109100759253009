.rootHeader {
  /* max-width: 345px; */
  background-color: #AA3C43 !important;
  padding-bottom: 10px;
}

.media {
  /* height: 600px;
  width:100%; */
  max-width: 100%;
  height: 75vh;
  object-fit: contain;
}

.headerImg {
    width: 100%;
    height: 100%; 
}

.headerText {
    color:#fff !important; 
    text-align: center;
}

.cardButtons {
    justify-content: center;
}

.contactUsButton {
    color:#fff !important; 
    border: 1px solid #fff !important;
}
