.Footer-bar {
    text-align: center;
    bottom: 0;
    background-color: black;
    color: white;
    padding: 40px;
  }

  .FSDLink {
    color: white;
  }
  